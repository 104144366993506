@import 'styles/utils/variables';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 2.6rem;
  font-weight: 700;
  color: $text-color-primary;
  margin-bottom: 2rem !important;
}

.goBack {
  font-size: 1.8rem;
  font-weight: 400;
  color: $text-color-secondary-active;
  margin-right: 2rem;
}

.contentWrap {
  height: 100%;
}

.content {
  text-align: center;
}

.title {
  font-size: 2rem !important;
  line-height: 1.2 !important;
}

.text {
  font-size: 1.4rem !important;
}

.flexContainer {
  display: flex;
  justify-content: center;
}

.linkBtnContainer {
  margin-right: 2rem;
}
