@import 'styles/utils/variables';

.upload {
  min-height: 12rem !important;

  background: $background-color-input !important;
  border: 1px dashed $border-color-input !important;
  display: flex;
  align-items: center;
  justify-content: center;

  & span[class$='ant-typography'] {
    color: $text-color-sub-nav !important;
  }

  & span[class$='ant-upload ant-upload-btn'] {
    padding: 0 !important;
  }
}

.fileName {
  color: $text-color-primary !important;
}

.removeFileBtn {
  background: $background-color-secondary-dark !important;
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;

  &:hover {
    background: $background-color-secondary-lite;
  }
}

.required {
  color: red;
  font-size: 1.4rem;
}
