@import 'styles/utils/variables';

.autoCompleteInput {
  & div[class$='ant-select-selector'] {
    background: $background-color-input !important;
    border: 0.5px solid $border-color-input !important;
    border-radius: 3px !important;

    & input[class$='ant-select-selection-search-input'] {
      font-weight: 600 !important;
    }

    & span[class$='ant-select-selection-placeholder'] {
      color: $text-color-placeholder !important;
      font-weight: normal !important;
      font-size: 1.4rem !important;
    }
  }

  &[class*='ant-select-open'] > div[class$='ant-select-selector'],
  &[class*='ant-select-focused'] > div[class$='ant-select-selector'],
  &:hover div[class$='ant-select-selector'],
  &:focus div[class$='ant-select-selector'] {
    border-color: $border-color-primary-hover !important;
  }
}
