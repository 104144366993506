@import 'styles/utils/variables';

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  background-color: $background-color-main !important;
}

.wrap {
  @extend .contentContainer;

  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.container {
  &[class^='ant-layout-content'] {
    height: 100% !important;
  }
}
