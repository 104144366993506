@import 'styles/utils/variables';

.urlInput {
  & input[type='text'] {
    background: $background-color-input;
    border-color: $border-color-input;
    border-radius: 3px;
    border-left-color: transparent;
    font-weight: 600;

    &:hover,
    &:focus {
      border-color: $border-color-primary-hover;
    }

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }

  span[class$='ant-input-group-addon'] {
    background: $background-color-input;
    border-color: $border-color-input;
    border-right-color: transparent;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 1.6rem;
    color: $text-color-placeholder;
  }
}
