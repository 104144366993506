@import '../utils/variables';
@import 'styles/utils/mixins';

html {
  font-size: 62.5%; // 1rem === 10px
  font-family: $font-family;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: #fff;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0 !important;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.heading {
  margin: 0;
  font-size: 2.6rem;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 1.53;
}

.text {
  font-size: 1.8rem;
  font-weight: $font-weight-base;
  line-height: 1.77;
}

//** OVERRIDES the user agent style sheets inside inputs / textarea / selects
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-input {
  color: $text-color-primary;
}

.ant-layout,
.ant-layout-header,
.ant-layout-footer {
  background-color: transparent !important;
}

.ant-input-prefix {
  margin-right: 1.6rem !important;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error .ant-input-group-addon,
.ant-form-item-has-error .ant-input-group-addon,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: $background-color-error !important;
}

//** OVERRIDES countDown styles
.ant-statistic-content {
  font-size: 1.2rem !important;
}

//** SUB PAGE CONTAINER
.subPageLayout {
  height: 100%;
  flex: auto;
}

//** Action modal scroll layout. Added scroll bar to the content inside modal window
.modalScrollLayout {
  overflow-y: scroll;
  padding-right: 1.4rem;
  // 100vh - header height - modal top and bottom paddings
  height: calc(100vh - 7.3rem - 4rem - 4rem);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
  }

  @include mq(lg) {
    height: 100%;
    overflow-y: auto;
    padding-right: 0;
  }
}

.modalScrollLayoutSmall {
  @extend .modalScrollLayout;
  height: 70vh;
}

//** Action modal sidebar anchors
.anchorLink {
  font-size: 1.6rem;
  line-height: 1.5715;
  padding: 1.8rem 0 1.8rem 1.6rem;
}

.sideBarAnchor {
  overflow-y: hidden;

  & div[class$='ant-anchor'] {
    & div[class$='ant-anchor-ink'] {
      &::before {
        background-color: #dbdce2;
        border-radius: 5px;
      }

      & span[class$='ant-anchor-ink-ball visible'] {
        border: 1px solid $border-color-primary;
        background-color: $background-color-primary;
        transform: translate(-50%, -2.6rem);
        border-radius: 5px;
        height: 6.05rem;
        width: 0.3rem;
      }
    }

    & div[class$='ant-anchor-link'] {
      @extend .anchorLink;

      & a {
        color: $text-color-sub-nav;
      }
    }

    & div[class$='ant-anchor-link-active'] {
      @extend .anchorLink;
      font-weight: 600;

      & a {
        color: $text-color-primary;
      }
    }
  }
}

//** Action modal info card
.infoCard {
  border-radius: 5px;
  border: 0.5px solid $border-color-secondary;
  margin-bottom: 0.8rem;

  & div[class$='ant-card-head'] {
    border-bottom: 0.5px solid $border-color-secondary;
    // padding: 3rem 2.4rem;
    padding: 3.2rem;

    @include mq(xs) {
      padding: 1.6rem;
    }
  }

  & div[class$='ant-card-body'] {
    padding: 3.2rem;

    @include mq(xs) {
      padding: 1.6rem;
    }
  }

  & div[class$='ant-card-head-title'] {
    padding: 0;
  }

  & div[class$='ant-card-extra'] {
    padding: 0;
  }
}

//** TYPOGRAPHY
.ant-typography.ant-typography-success {
  color: $text-color-success;
}

.ant-typography.ant-typography-warning {
  color: $text-color-warning;
}

.ant-typography.ant-typography-danger {
  color: $text-color-error;
}

.ant-typography.ant-typography-secondary {
  color: $text-color-secondary;
}

//? Page controls action button
.actionBtn {
  display: flex;
  align-items: center;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  border-radius: 3px !important;
  font-size: 1.2rem !important;
  font-weight: 600;

  & svg {
    margin-right: 0.2rem;
  }
}

//** no-active inputs (for profile pages)
.disabledField {
  cursor: auto;

  &.active {
    color: $text-color-success;
    background: $background-color-success !important;
    border-color: $border-color-success;
  }

  &.inactive {
    color: $text-color-error;
    background: $background-color-error !important;
    border-color: $border-color-error;
  }

  &.default {
    background: $background-color-default !important;
    border-color: $border-color-default !important;
  }

  &.error {
    color: $text-color-error;
    background: $background-color-error !important;
    border-color: $border-color-error !important;
  }

  &.success {
    color: $text-color-success;
    background: $background-color-success !important;
    border-color: $border-color-success !important;
  }

  &.warning {
    color: $text-color-warning;
    background: $background-color-warning !important;
    border-color: $border-color-warning !important;
  }

  &.blue {
    color: $text-color-blue !important;
    background: $background-color-blue !important;
    border-color: $border-color-blue !important;
  }

  &.groupPlaceholder {
    &::placeholder {
      text-align: center;
      font-weight: normal !important;
      color: $text-color-placeholder !important;
    }
  }

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    font-weight: 600 !important;
    color: $text-color-primary !important;
  }
}

.textFieldDisabled {
  @extend .disabledField;
  pointer-events: none;
}

//** Profiles Pages ScrollLayout
.profileScrollLayout {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
  }
}

//** Profiles Pages infoCard
.profileInfoCard {
  border-radius: 5px;
  border: 0.5px solid $border-color-input-light;

  & div[class$='ant-card-head'] {
    border-bottom: none;
  }

  & div[class$='ant-card-body'] {
    padding: 2.4rem;
  }

  & div[class$='ant-card-head-title'] {
    padding: 2.4rem 0 0.8rem;
    font-weight: 600 !important;
  }
}

//** Overrides ant style injection (added only with responsive des)
body[class='ant-scrolling-effect'] {
  width: 100% !important;
}

// overflow: scroll in all pages
.rc-virtual-list-holder {
  overflow-y: auto !important;
  overflow-x: auto !important;
  overflow-anchor: visible !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px !important;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-thumb-hover;
}

.ant-menu-submenu-arrow {
  display: none !important;
}

.tdNumberRightAlign {
  text-align: right;
}

.disabledLink {
  pointer-events: none !important;
  cursor: default;
}

.printOnly {
  display: none;
}

@media print {
  body {
    zoom: 80% !important;
    width: 100%;
    max-width: 100%;
    -webkit-text-size-adjust: 100%;

    @-moz-document url-prefix() {
      @page {
        margin-top: 0.2in !important;
      }
    }
  }

  @page {
    margin-top: 0.2in;
  }

  @-moz-document url-prefix() {
    @page {
      margin-top: 0 !important;
    }
  }

  .printArea {
    -webkit-text-size-adjust: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    page-break-inside: avoid;
    overflow: visible;
    padding: 0;
    margin: 0;

    .no-print,
    .no-print * {
      display: none !important;
    }

    .biggerText {
      font-size: 15px !important;

      @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        font-size: 12px !important;
      }
    }

    .oneStyle {
      background: $background-color-shift-success !important;
      border: 1px solid $border-color-shift-success !important;
    }

    .printOnly {
      display: block;
    }

    .scheduleHeaderPrint {
      display: flex;
      margin-bottom: 10px;
    }

    .scheduleTitlePrint {
      position: relative !important;
      font-size: 22px !important;
      margin-left: 40px;
      color: #6e74a9;
      top: 6px !important;
    }
  }
}

.bodyPrintStyles {
  transform: scale(0.94);
}
