.ant-slider {
  height: 0.5rem !important;
}

.ant-slider-rail {
  background-color: #eaebee !important;
}

.ant-slider-track {
  background-color: #1890ff !important;
}

.ant-slider-handle {
  background-color: #fff !important;
  border: 3px solid #1b96ff !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.ant-slider-mark-text {
  color: #8b8f9e !important;
  font-size: 1.2rem !important;
}

.ant-slider-dot {
  display: none !important;
}
