$font-family: 'Oxygen', sans-serif;

//font-weight
$font-weight-base: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

//all app styles text-color
$text-color-primary: #2e3a59;
$text-color-primary-white: #ffffff;
$text-color-primary-black: #000000;
$text-color-secondary: #979cac;
$text-color-secondary-light: #f4f4f4;
$text-color-secondary-dark: #909090;
$text-color-secondary-grey: #626b83;
$text-color-secondary-hover: #40a9ff;
$text-color-secondary-active: #096dd9;
$text-color-error: #ff5a30;
$text-color-error-hover: #ff7875;
$text-color-error-active: #d9363e;
$text-color-success: #65bf73;
$text-color-error: #ff5a30;
$text-color-warning: #faad14;
$text-color-default: #2e3a59;
$text-color-blue: #315efb;
$text-color-selected: #1b96ff;
$text-color-placeholder: #979cac;
$text-color-sub-nav: #616a81;
$text-color-disabled: #8c93a3;
$text-color-tableHead-mobile: #808a93;
$text-color-violet: #6f00ff;

//bg-colors
$background-color-main: #f9f9fa;
$background-color-main-active: #f4f6f9;
$background-color-primary: #1b96ff;
$background-color-primary-hover: #40a9ff;
$background-color-primary-active: #096dd9;
$background-color-primary-success: #65bf73;
$background-color-primary-success-hover: #73cc80;
$background-color-primary-success-active: #50a85d;
$background-color-danger: #ff5a30;
$background-color-danger-hover: #ff7875;
$background-color-danger-active: #dc251c;
$background-color-secondary: #ffffff;
$background-color-secondary-lite: #414e6e;
$background-color-secondary-dark: #2e3a59;
$background-color-success: #f0f9f1;
$background-color-error: #ffefea;
$background-color-csv-error: #ffefea;
$background-color-warning: #fdf3da;
$background-color-default: #eaebee;
$background-color-blue: #e8f4ff;
$background-color-orange: #ff8000;
$background-color-selected: #e8f4ff;
$background-color-disabled: #eaebee;
$background-color-input: #f5f5f7;
$background-color-input-light: #f7f9fc;

// border colors
$border-color-primary: #1b96ff;
$border-color-primary-hover: #40a9ff;
$border-color-primary-active: #096dd9;
$border-color-primary-success: #65bf73;
$border-color-primary-success-hover: #73cc80;
$border-color-primary-success-active: #50a85d;
$border-color-error: #ff5a30;
$border-color-error-hover: #ff7875;
$border-color-secondary: #e0e1e6;
$border-color-secondary-light: #f4f4f4;
$border-color-secondary-hover: #40a9ff;
$border-color-default: #2e3a59;
$border-color-input: #c3c6cf;
$border-color-input-light: #e2e3e6;
$border-color-success: #65bf73;
$border-color-warning: #faad14;
$border-color-blue: #315efb;
$border-color-selected: #e8f4ff;

// box-shadow colors
$box-shadow-color-active: 0 0 0 0.2rem rgba(24, 144, 255, 0.2);
$box-shadow-color-error-active: 0 0 0 0.2rem rgba(255, 77, 79, 0.2);

// scrollbar
$scrollbar-track: #f5f5f7;
$scrollbar-thumb: #b6bac5;
$scrollbar-thumb-hover: #777f93;

// divider
$divider-color: #bdc3c7;

// shift
$background-color-shift-default: #f5f5f7;
$background-color-shift-success: #f0f9f1;
$background-color-shift-error: #ffefea;
$background-color-shift-warning: #fff3d7;
$background-color-shift-custom: #d9d2fa;
$background-color-shift-blue: #ddfcff;

$border-color-shift-default: #c3c6cf;
$border-color-shift-success: #65bf73;
$border-color-shift-error: #ff5a30;
$border-color-shift-warning: #faad14;
$border-color-shift-custom: #7b61ff;
$border-color-shift-blue: #2c86d4;

$box-shadow-color-shift-default: 0 0 1px 0.2rem rgba(195, 198, 207, 0.2);
$box-shadow-color-shift-success: 0 0 1px 0.2rem rgba(115, 204, 128, 0.2);
$box-shadow-color-shift-error: 0 0 1px 0.2rem rgba(255, 90, 48, 0.2);
$box-shadow-color-shift-warning: 0 0 1px 0.2rem rgba(250, 173, 20, 0.2);
$box-shadow-color-shift-custom: 0 0 1px 0.2rem rgba(123, 97, 255, 0.2);

// calendar
$border-color-event: #3eaf3f;
$text-color-event: #3eaf3f;
$border-color-cell: #ddd;
$background-color-event: #65bf731a;
$background-color-event-job: #e7f4ff;
$date-background-color: #2e3a59bf;
$current-date-background-color: #1b96ff;

// severity-colors
$severity-low: #008000;
$severity-medium: #ffff00;
$severity-high: #ffa500;
