@import 'styles/utils/mixins';
@import 'styles/utils/variables';

.navMenu {
  justify-content: center;
  border: transparent !important;

  @include mq(md) {
    display: flex;
    justify-content: flex-start;
  }

  & li[class*='ant-menu-submenu'] {
    top: 0.4rem;

    @include mq(md) {
      padding: 0 !important;
    }
  }
}

.navMenuItem {
  font-size: 1.4rem;
  padding: 0 0.9rem !important;

  @include mq(lg) {
    font-size: 1.6rem;
  }

  & span[class$='ant-menu-title-content'] a {
    color: $text-color-primary !important;
  }

  &:hover span[class$='ant-menu-title-content'] a {
    color: $text-color-selected !important;
  }

  &[class*='ant-menu-item-selected'] {
    & span[class$='ant-menu-title-content'] a {
      color: $text-color-selected !important;
    }
  }

  &::after {
    right: 0.9rem !important;
    left: 0.9rem !important;
  }
}

.subMenuIcon {
  display: inline !important;
  margin-top: -0.5rem;
  border: none !important;
}
