@import 'styles/utils/mixins';
@import 'styles/utils/variables';

.authHeader {
  border-bottom: 1px solid $border-color-secondary-light;

  @include mq(sm) {
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    padding: 2rem 2rem !important;
    height: auto !important;
  }
}

.authHeaderTitle {
  text-align: right;

  @include mq(md) {
    padding-right: 1rem;
  }

  @include mq(sm) {
    text-align: left;
  }
}

.authHeaderLogo {
  display: flex;
}

.authHeaderBtnWrap {
  text-align: right;
}

.authHeaderBtn {
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center !important;

  @include mq(sm) {
    height: auto !important;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
  }

  & svg {
    margin-top: 0.4rem !important;
    margin-left: 0.4rem !important;

    @include mq(sm) {
      margin-top: 0.2rem !important;
    }
  }
}

.languageSelect {
  text-align: right;
}

.goIntroBtn {
  margin-left: 3rem;
}
