@import 'styles/utils/variables';

.switch {
  div[class$='ant-switch-inner']svg {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    background-color: $background-color-default;
  }

  &[aria-checked='true'] {
    background-color: $background-color-primary !important;

    & div[class$='ant-switch-handle']::before {
      background-color: $background-color-secondary !important;
    }
  }

  &[aria-checked='false'] {
    background-color: $background-color-default !important;

    & div[class$='ant-switch-handle']::before {
      background-color: $background-color-secondary-dark !important;
    }
  }
}
