@import 'styles/utils/variables';

.checkbox {
  &[class*='ant-checkbox-wrapper'] {
    color: $text-color-primary;
    align-items: center;
    display: flex !important;
    top: 0 !important;
  }

  &[class*='ant-checkbox-wrapper'] + &[class*='ant-checkbox-wrapper'] {
    margin-left: 0;
  }

  & > span[class='ant-checkbox'],
  & > span[class='ant-checkbox-disabled'],
  & > span[class='ant-checkbox ant-checkbox-checked'],
  & > span[class='ant-checkbox ant-checkbox-indeterminate'],
  & > span[class='ant-checkbox ant-checkbox-checked ant-checkbox-disabled'],
  & > span[class='ant-checkbox ant-checkbox-disabled'] {
    top: 0 !important;
  }

  & span[class='ant-checkbox'] span[class*='ant-checkbox-inner'] {
    background: $background-color-input;
    border: 0.5px solid $border-color-input;
    border-radius: 3px;
  }
}

.large {
  & span[class='ant-checkbox'],
  & span[class='ant-checkbox ant-checkbox-indeterminate'],
  & span[class='ant-checkbox ant-checkbox-checked'] {
    & input {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  & span[class*='ant-checkbox-inner'] {
    width: 2.4rem;
    height: 2.4rem;

    &::after {
      top: 10px;
      left: 26%;
      width: 6px;
      height: 12px;
    }
  }
}

.middle {
  & span[class='ant-checkbox'],
  & span[class='ant-checkbox ant-checkbox-indeterminate'],
  & span[class='ant-checkbox ant-checkbox-checked'] {
    & input {
      width: 2rem;
      height: 2rem;
    }
  }

  & span[class*='ant-checkbox-inner'] {
    width: 2rem;
    height: 2rem;

    &::after {
      left: 25%;
      top: 8px;
      width: 6px;
      height: 10px;
    }
  }
}

.small {
  & span[class='ant-checkbox'],
  & span[class='ant-checkbox ant-checkbox-indeterminate'],
  & span[class='ant-checkbox ant-checkbox-checked'] {
    & input {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  & span[class*='ant-checkbox-inner'] {
    width: 1.6rem;
    height: 1.6rem;

    &::after {
      left: 30%;
      width: 5px;
      height: 7px;
    }
  }
}
