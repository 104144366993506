.groupSelect {
  width: 70px;
  border-radius: 2px;

  & .ant-select-arrow {
    color: #fff;
  }

  & .ant-select-selection-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.groupSelect:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fe5c4c;
  border: 1px solid #fe5c4c;
  border-radius: 2px;
}

.groupSelect:not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: #e74c3c;
}

.ant-select-focused:not(.ant-select-disable).groupSelect:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #e74c3c;
  box-shadow: 0 0 0 2px rgba(254, 92, 76, 0.2);
}

.dropdownSelect {
  background-color: #fe5c4c;

  & .ant-select-item-option-selected {
    background-color: transparent;
  }

  & .ant-select-item-option-active {
    background-color: #ff796d;
  }

  & .ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
