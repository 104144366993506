@import 'styles/utils/variables';

.timePicker {
  &[class*='ant-picker'] {
    background: $background-color-input;
    border: 0.5px solid $border-color-input;
    border-radius: 3px;
    width: 100%;

    &[class*='ant-picker-focused'],
    &:hover,
    &:focus {
      border-color: $border-color-primary-hover !important;
    }
  }

  & input {
    font-weight: 600;

    &::placeholder {
      color: $text-color-placeholder !important;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }
}
