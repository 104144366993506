@import 'styles/utils/variables';

.label {
  color: $text-color-placeholder;
  font-weight: 600;
  font-size: 1.2rem !important;
  line-height: 1;
  display: flex;
  margin-bottom: 0.5rem;
}

.itemWrap {
  margin-bottom: 0 !important;
}

.bigLabel {
  & div[class$='ant-form-item-label'] label {
    font-size: 1.4rem !important;
  }
}

.switchItem {
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-bottom: 0 !important;

  & div[class$='ant-form-item-label'] {
    padding: 0 !important;
    font-weight: 600;
    color: $text-color-primary;
  }

  & div[class$='ant-form-item-label'] label {
    line-height: 2rem !important;
    font-size: 1.4rem !important;
  }

  & div[class$='ant-form-item-control'] {
    align-items: flex-end !important;
  }
}
