@import 'styles/utils/variables';

button.ant-btn {
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-radius: 4px;
  border-color: $border-color-input;
  transition: all 250ms linear;
  color: $text-color-primary;

  &:hover {
    color: $text-color-secondary-hover;
  }

  &:active {
    color: $text-color-secondary-active;
  }

  &.ant-btn-primary:not(.ant-btn-dangerous) {
    color: $text-color-primary-white;
    border-color: $border-color-primary;
    background: $background-color-primary;

    &:hover {
      border-color: $border-color-primary-hover;
      background: $background-color-primary-hover;
    }

    &:active {
      border-color: $border-color-primary-active;
      background: $background-color-primary-active;
    }
  }

  &.ant-btn-primary[disabled],
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary[disabled]:active {
    border-color: $border-color-secondary;
    background: $background-color-input;
    color: $text-color-secondary;
  }

  &.ant-btn-lg {
    font-size: 1.6rem;
    line-height: 1.8rem;
    border-radius: 4px;
  }

  &.ant-btn-sm {
    font-size: 1.2rem;
    line-height: 1.4rem;
    border-radius: 4px;
  }

  &.ant-btn-link:not(.ant-btn-dangerous) {
    border-color: transparent;
    color: $text-color-selected;
    font-weight: 600;
  }

  &.ant-btn-link[disabled] {
    color: $text-color-secondary !important;
  }

  &.ant-btn-primary.ant-btn-dangerous:not([disabled]) {
    color: $text-color-primary-white !important;
    border-color: $border-color-error !important;
    background: $background-color-danger !important;

    &:hover,
    &:focus {
      border-color: $border-color-error-hover;
      color: $text-color-error-hover;
    }
  }

  &.ant-btn-default.ant-btn-dangerous:not([disabled]) {
    color: $text-color-error;
    border-color: $border-color-error;
    background: $background-color-secondary;

    &:hover,
    &:focus {
      border-color: $border-color-error-hover;
      color: $text-color-error-hover;
    }
  }

  &.ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875 !important;
}

.goBackBtn {
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  color: $text-color-secondary-dark !important;
  transition: all 250ms linear !important;

  & svg {
    margin-top: 2px !important;
  }

  &:hover {
    color: $text-color-secondary !important;
  }
}
