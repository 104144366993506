@import 'styles/utils/variables';

.dateRangePicker {
  border-radius: 3px !important;
  border: 0.5px solid $border-color-input !important;

  & div[class^='ant-picker-input'] input {
    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal !important;
      font-size: 1.4rem !important;
    }
  }

  &[class*='ant-picker-focused'],
  &:hover,
  &:focus {
    border-color: $border-color-primary-hover !important;
  }
}
