@mixin mq($breakpoint) {
  @if $breakpoint==xxxxl {
    @media only screen and (max-width: 1680.98px) {
      @content;
    }
  }
  @if $breakpoint==xxxl {
    @media only screen and (max-width: 1399.98px) {
      @content;
    }
  }
  @if $breakpoint==xxl {
    @media only screen and (max-width: 1280.98px) {
      @content;
    }
  }
  @if $breakpoint==xl {
    @media only screen and (max-width: 1199.98px) {
      @content;
    }
  }
  @if $breakpoint==lg {
    @media only screen and (max-width: 991.98px) {
      @content;
    }
  }
  @if $breakpoint==md {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint==sm {
    @media only screen and (max-width: 575.98px) {
      @content;
    }
  }
  @if $breakpoint==xs {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
  @if $breakpoint==xxs {
    @media only screen and (max-width: 350px) {
      @content;
    }
  }
}

@mixin mqh($breakpoint) {
  @if $breakpoint==xxxl {
    @media only screen and (max-height: 1399.98px) {
      @content;
    }
  }
  @if $breakpoint==xxl {
    @media only screen and (max-height: 1199.98px) {
      @content;
    }
  }
  @if $breakpoint==xl {
    @media only screen and (max-height: 1099.98px) {
      @content;
    }
  }
  @if $breakpoint==lg {
    @media only screen and (max-height: 799.98px) {
      @content;
    }
  }
  @if $breakpoint==md {
    @media only screen and (max-height: 699.98px) {
      @content;
    }
  }
}

@mixin mqhw($maxHeight, $maxWidth) {
  @media only screen and (max-height: $maxHeight) and (max-width: $maxWidth) {
    @content;
  }
}
